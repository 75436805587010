import { useCallback, useEffect, useState } from 'react';

interface WindowDimensions {
  width: number | undefined;
  height: number | undefined;
}

function useWindowDimensions(debounceTime = 200): WindowDimensions {
  const [dimensions, setDimensions] = useState<WindowDimensions>({
    width: undefined,
    height: undefined,
  });

  const handleResize = useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();
      const debouncedHandleResize = debounce(handleResize, debounceTime);

      window.addEventListener('resize', debouncedHandleResize);

      return () => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
    }
  }, [handleResize, debounceTime]);

  return dimensions;
}

export default useWindowDimensions;

export function debounce(func: () => void, timeout = 300): () => void {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, timeout);
  };
}
